// src/MenuBar.js
import React, { useState, useEffect } from 'react';
import './MenuBar.css';
import menuIcon from './menu-icon.svg'; // Import your menu icon
import closeIcon from './close-icon.svg'; // Import your close icon
import Hamburger from 'hamburger-react';
import "wc-menu-button";

function MenuBar({ imgPath, handleMenuItemClick }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const toggleMenu = () => {
    setIsVisible(!isVisible);
  };

  const handleResize = () => {
    const mobileView = window.innerWidth <= 768;
    setIsMobile(mobileView);
    if (mobileView) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const handleClick = (id) => {
    if(isMobile)toggleMenu();
    handleMenuItemClick(id); // Call the provided function
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initialize the state based on the current screen size
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className={`menu-bar ${isVisible ? 'visible' : 'hidden'}`}>
        <div class="content-holder">
          <img class="menu-bar-img" src={imgPath} />
          <h3 class="menu-bar-title">Mara & Mircea</h3>
          <h5 class="menu-bar-subtitle">4 Octombrie 2024</h5>
          <ul>
            <li class="listItem" onClick={() => handleClick('home')}>Acasă</li>
            <li class="listItem" onClick={() => handleClick('about-us')}>Noi doi</li>
            <li class="listItem" onClick={() => handleClick('location-detail')}>Unde și când</li>
            <li class="listItem" onClick={() => handleClick('confirm')}>Confirmare</li>
          </ul>
        </div>
        <div>
          <p class="menu-copyright">© Mara & Mircea Martin</p>
        </div>
      </div>
      {isMobile && (
        <button className="toggle-button" onClick={toggleMenu}>
          <img src={isVisible ? closeIcon : menuIcon} alt={isVisible ? 'Close Menu' : 'Open Menu'} />
        </button>
      )}
    </>
  );
};

export default MenuBar;
