import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBmIV-Oa2ht8vmDI78-gCak5zx5119MOMM",
  authDomain: "weddinger-388ab.firebaseapp.com",
  projectId: "weddinger-388ab",
  storageBucket: "weddinger-388ab.appspot.com",
  messagingSenderId: "826813078467",
  appId: "1:826813078467:web:a8ddc8788c9e6b8f5f3b76",
  measurementId: "G-8YH50L2SJZ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };