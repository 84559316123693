import React from "react";
import './CountdownElement.css'

function CountdownElement({label, value}){
    return(
        <div className="countdownElementHolder">
            <p className="value">{value.toString().padStart(2, '0')}</p>
            <p className="label">{label}</p>
        </div>
    )
}

export default CountdownElement;