import React from "react";
import './IconWithText.css'

function IconWithText({ iconUrl, text }) {
    const [firstText, ...remainingText] = text;
    return (
        <div class="iconWithTextHolder">
            <div class="imgAndTextHolder">
                <img class="iconWithTextIcon" src={iconUrl} />
                <h5 class="iconWithTextFirstText">{firstText}</h5>
            </div>
            <div class="textHolder">
            {remainingText.map((textItem, index) => (
                <h5 key={index}>{textItem}</h5>
            ))}
            </div>
        </div>
    )
}

export default IconWithText;