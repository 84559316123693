import React from "react";
import './IconWithLink.css'

function IconWithLink({ iconUrl, text, href }) {
    return (
        <div class="iconWithLinkHolder">
            <div class="imgAndTextHolder">
                <img class="iconWithTextIcon" src={iconUrl} />
                <a href={href}><h5>{text}</h5></a>
            </div>
        </div>
    )
}

export default IconWithLink;