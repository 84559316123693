import React from "react";
import './LocationDetail.css';
import IconWithText from "../iconwithText/IconWithText";
import IconWithLink from "../iconwithlink/IconWithLink";

function LocationDetail({ title, subtitle, imgUrl, title2, place, placeIcon, dateAndTime, dateAndTimeIcon, locationText, locationIcon, locationLink,
    subtitle2, imgUrl2, title3, place2, placeIcon2, dateAndTime2, dateAndTimeIcon2, locationText2, locationIcon2, locationLink2
}) {
    return (
        <div class="locationDetailHolder">
            <h2 class="locationDetailSubtitle">{subtitle}</h2>
            <div class="locationSectionHolder">
                <div class="locationSection">
                    <img class="locationDetailImage" src={imgUrl} />
                    <h4 class="locationDetailSectionTitle">{title2}</h4>
                    <IconWithText iconUrl={placeIcon} text={place} />
                    <IconWithText iconUrl={dateAndTimeIcon} text={dateAndTime} />
                    <IconWithLink iconUrl={locationIcon} text={locationText} href={locationLink} />
                </div>
                <div class="locationSection">
                    <img class="locationDetailImage" src={imgUrl2} />
                    <h4 class="locationDetailSectionTitle">{title3}</h4>
                    <IconWithText iconUrl={placeIcon} text={place2} />
                    <IconWithText iconUrl={dateAndTimeIcon} text={dateAndTime2} />
                    <IconWithLink iconUrl={locationIcon} text={locationText2} href={locationLink2} />
                </div>
            </div>
        </div>
    )
}

export default LocationDetail;