// src/CustomAlertDialog.js
import React from 'react';
import './CustomAlertDialog.css';

const CustomAlertDialog = ({ isOpen, message, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="overlay">
      <div className="alert-dialog">
        <p>{message}</p>
        <button onClick={onClose}>OK</button>
      </div>
    </div>
  );
};

export default CustomAlertDialog;
