import React, { useState } from 'react';
import './App.css';
import { db } from './firebase/firebaseConfig.js';
import { addDoc, collection } from "firebase/firestore";
import CountDownToDate from './components/countdown/CountdownToDate'
import HeaderCard from './components/headercard/HeaderCard';
import HighlightedTitleWithSubtitle from './components/highlightedtitlewithsubtitle/HighlightedTitleWithSubtitle';
import TextWithBackgroundAndBottomDate from './components/TextWithBackgoundAndBottomDate/TextWithBackgroundAndBottomDate';
import LocationDetail from './components/locationDetails/LocationDetail';
import MenuBar from './components/menubar/MenuBar.js';
import CustomAlertDialog from './components/customalertdialog/CustomAlertDialog.js';



function App() {

  const [showNameError, setNameShowError] = useState(false);
  const [showFirstNameError, setFirstNameShowError] = useState(false);
  const [showNumberOfPeopleError, setNumberOfPeopleShowError] = useState(false);
  const [showMenuError, setMenuShowError] = useState(false);
  const [showPhoneError, setPhoneShowError] = useState(false);
  const [showIntoleranceError, setIntoleranceShowError] = useState(false);


  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertOpen(true);
  };

  const closeAlert = () => {
    setIsAlertOpen(false);
  };

  const [guestData, setGuestData] = useState({
    name: '',
    firstName: '',
    phoneNumber: '',
    numberOfPeople: 0,
    menuType: 'choose-menu', // Default menu option
    numberOfKids: 0,
    intolerance: "",
  });

  const handleInputChange = (event) => {
    setGuestData({
      ...guestData,
      [event.target.name]: event.target.value,
    });
    console.log(event.target.value)
    if (showNameError && event.target.name == "name" && event.target.value != "") setNameShowError(false)
    if (showFirstNameError && event.target.name == "firstName" && event.target.value != "") setFirstNameShowError(false)
    if (showNumberOfPeopleError && event.target.name == "numberOfPeople" && event.target.value != "0") setNumberOfPeopleShowError(false)
    if (showMenuError && event.target.name == "menuType" && event.target.value != "choose-menu") setMenuShowError(false)
    if (showPhoneError && event.target.name == "phoneNumber" && event.target.value != "") setPhoneShowError(false)
    if (showIntoleranceError && event.target.name == "intolerance" && event.target.value != "") setIntoleranceShowError(false)
  };

  const handleAddGuest = async () => {
    if (guestData.name == "") {
      setNameShowError(true)
    } else if (guestData.firstName == "") {
      setFirstNameShowError(true)
    } else if (guestData.numberOfPeople == "0") {
      setNumberOfPeopleShowError(true)
    } else if (guestData.phoneNumber == "") {
      setPhoneShowError(true)
    } else if (guestData.menuType == "choose-menu") {
      setMenuShowError(true)
    } else if (guestData.menuType == "intoleranta" && guestData.intolerance == "") {
      setIntoleranceShowError(true)
    } else {
      try {
        const guestRef = collection(db, "weddingGuests");
        const newGuestDoc = await addDoc(guestRef, guestData);
        /*setGuestData({ // Clear form after successful addition
          name: '',
          firstName: '',
          numberOfPeople: 0,
          menuType: 'choose-menu',
          numberOfKids: 0,
        });*/
        //alert("Confirmarea a fost facuta cu succes!")
        showAlert('Va multumim pentru confirmare. Va asteptam cu drag! ❤️')
      } catch (error) {
        console.error("Error adding guest:", error);
        // Handle errors appropriately (e.g., display an error message to the user)
      }
    }
  };

  const handleMenuItemClick = (id) => {
    console.log('Clicked menu item:', id);
    smoothScroll(id);
    // Add your logic for handling menu item clicks here
  };

  const smoothScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <CustomAlertDialog
        isOpen={isAlertOpen}
        message={alertMessage}
        onClose={closeAlert}
      />
      <div className="App">
        <MenuBar imgPath={'/assets/wedding_ring.svg'}
          handleMenuItemClick={handleMenuItemClick} />
        <div className="content">
          <div class="section1" id="home">
            <div></div>
            <div class="section1Content">
              <h2 class="section1Title">Mara & Mircea</h2>
              <p class="section1Subtitle">4 Octombrie · Oradea</p>
            </div>
            <img src={"/assets/hearth1_outline.svg"} class="bouncing-hearth" onClick={() => handleMenuItemClick('about-us')} />
          </div>
          <div class="section2" id="about-us">
            <HeaderCard
              title1={"Mara"}
              title2={"Mircea"}
              titleImg={"/assets/heart_outline.svg"}
              description={
                ["De 5 ani împreună, pe parcursul cărora am împărtășit momente și experiențe ce ne-au consolidat relația.",
                  "Începând cu această zi vom păși spre viitor cu planuri ambițioase, cu visuri mărețe și cu forțe proaspete, unite."]
              } />
            <HighlightedTitleWithSubtitle
              title={"Împreună cu nașii"}
              subtitle={[
                { text: 'Carla', isDead: false },
                { text: 'Andrei Timoce', isDead: false },
              ]} />
            <HighlightedTitleWithSubtitle
              title={"Alături de părinții noștri"}
              subtitle={[
                { text: 'Maria', isDead: true },
                { text: 'Dorin Anghel', isDead: false },
                { text: 'Ioana', isDead: false },
                { text: 'Leontin Martin', isDead: false },
              ]} />
          </div>
          <div class="section3">
            <CountDownToDate title={"Vom deveni o familie în:"} targetDate={"October 4, 2024 18:00:00"} backgroundImagePath={"/assets/countdown_background.jpg"} />
            <div class="leavesHolder">
              <img class="section3MiddleIcon"
                src='/assets/leaves_left.svg' />
              <img class="section3MiddleIconRight"
                src='/assets/leaves_left.svg' />
            </div>
            <TextWithBackgroundAndBottomDate date={"04 - Octombrie - 2024"}
              text={"Avem deosebita plăcere de a vă invita să fiți alături de noi!"}
              text2={"Vă așteptăm cu drag!"}
              icon={"/assets/hearth1_outline.svg"}
              backgroundImagePath={'/assets/countDownBackground.jpeg'} />
          </div>
          <div class="section5" id="location-detail">
            <LocationDetail
              subtitle={"Unde și Când"}
              imgUrl={"/assets/cununia_religioasa_background.jpg"}
              title2={"Cununia Religioasă"}
              place={["Opera Events, Grădina interioară", "Oradea", "Bihor"]}
              placeIcon={"/assets/location.svg"}
              dateAndTime={["4 Octombrie • 18:00"]}
              dateAndTimeIcon={"/assets/calendar.svg"}
              locationText={"Vezi pe Hartă"}
              locationIcon={"/assets/navigation.svg"}
              locationLink={"https://www.google.com/maps?sca_esv=04aa412ff406bf20&rlz=1C5CHFA_enAT1096AT1096&sxsrf=ADLYWIKCVSRRNlId7a_jlUGp3q_FJGFcyg:1720472909574&fbs=AEQNm0A6bwEop21ehxKWq5cj-cHa02QUie7apaStVTrDAEoT1A_pRBhSGXWPGL0_xk71SHEfA_FbknY8OGADbiEfVLzwIBQ_-PraIrBFqbcuvOtMNf5yKoeeKhtXEu0a4vVpMosGiuLRnjbjAgD0-tGzl3weV0bdPcbm9QYSBuMqM_YvkcETfHiWr3sBJVnWGvsY4OvbKBl0&biw=863&bih=992&dpr=2&um=1&ie=UTF-8&fb=1&gl=at&sa=X&geocode=Kb8cnkClR0ZHMVuVWY6-s8vT&daddr=Calea+Aradului+91A,+Oradea+410223,+Rum%C3%A4nien"}
              imgUrl2={"/assets/petrecerea_background.jpg"}
              title3={"PETRECEREA"}
              place2={["Opera Events", "Oradea", "Bihor"]}
              placeIcon2={"https://image.similarpng.com/very-thumbnail/2021/01/Location-icon-design-on-transparent-background-PNG.png"}
              dateAndTime2={["4 Octombrie • 19:00"]}
              dateAndTimeIcon2={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6R7yoHVIjhiysF1KmcGYjm0jdDitmQqosgA&s"}
              locationText2={"Vezi pe Hartă"}
              locationIcon2={"/assets/navigation.svg"}
              locationLink2={"https://www.google.com/maps?sca_esv=04aa412ff406bf20&rlz=1C5CHFA_enAT1096AT1096&sxsrf=ADLYWIKCVSRRNlId7a_jlUGp3q_FJGFcyg:1720472909574&fbs=AEQNm0A6bwEop21ehxKWq5cj-cHa02QUie7apaStVTrDAEoT1A_pRBhSGXWPGL0_xk71SHEfA_FbknY8OGADbiEfVLzwIBQ_-PraIrBFqbcuvOtMNf5yKoeeKhtXEu0a4vVpMosGiuLRnjbjAgD0-tGzl3weV0bdPcbm9QYSBuMqM_YvkcETfHiWr3sBJVnWGvsY4OvbKBl0&biw=863&bih=992&dpr=2&um=1&ie=UTF-8&fb=1&gl=at&sa=X&geocode=Kb8cnkClR0ZHMVuVWY6-s8vT&daddr=Calea+Aradului+91A,+Oradea+410223,+Rum%C3%A4nien"}
            />
          </div>
          <div class="footer" id="confirm">
            <h2 class="formTitle">Confirmați Participarea</h2>
            <form class="confirmationForm" onSubmit={(e) => e.preventDefault()}>
              <h3 class="form2ndSubtitle">Completează formularul de mai jos pentru a ne anunța decizia ta.</h3>
              <div class="confirmationFormItem">
                <label class="confirmationLabel"
                  htmlFor="name">Nume</label>
                <input
                  class="confirmationInput"
                  type="text"
                  id="name"
                  name="name"
                  value={guestData.name}
                  onChange={handleInputChange}
                />
                {showNameError && <div class="errorLabel"><p>Te rugăm să ne spui numele.</p></div>}
              </div>
              <div class="confirmationFormItem">
                <label class="confirmationLabel"
                  htmlFor="name">Prenume</label>
                <input
                  class="confirmationInput"
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={guestData.firstName}
                  onChange={handleInputChange}
                />
                {showFirstNameError && <div class="errorLabel"><p>Te rugăm să ne spui prenumele.</p></div>}
              </div>
              <div class="confirmationFormItem">
                <label class="confirmationLabel"
                  htmlFor="numberOfPeople">Numărul de Persoane</label>
                <select
                  class="confirmationInput"
                  id="numberOfPeople"
                  name="numberOfPeople"
                  value={guestData.numberOfPeople}
                  onChange={handleInputChange}>
                  <option class="confirmationInput" value="0">0</option>
                  <option class="confirmationInput" value="1">1</option>
                  <option class="confirmationInput" value="2">2</option>
                  <option class="confirmationInput" value="3">3</option>
                  <option class="confirmationInput" value="4">4</option>
                  <option class="confirmationInput" value="5">5</option>
                  <option class="confirmationInput" value="6">6</option>
                  <option class="confirmationInput" value="7">7</option>
                  <option class="confirmationInput" value="8">8</option>
                  <option class="confirmationInput" value="9">9</option>
                  <option class="confirmationInput" value="10">10</option>
                </select>
                {showNumberOfPeopleError && <div class="errorLabel"><p>Te rugăm să alegi numărul de persoane.</p></div>}
              </div>
              <div class="confirmationFormItem">
                <label class="confirmationLabel"
                  htmlFor="numberOfKids">Din care copii</label>
                <select
                  class="confirmationInput"
                  id="numberOfKids"
                  name="numberOfKids"
                  value={guestData.numberOfKids}
                  onChange={handleInputChange}>
                  <option class="confirmationInput" value="0">0</option>
                  <option class="confirmationInput" value="1">1</option>
                  <option class="confirmationInput" value="2">2</option>
                  <option class="confirmationInput" value="3">3</option>
                  <option class="confirmationInput" value="4">4</option>
                  <option class="confirmationInput" value="5">5</option>
                </select>
              </div>
              <div class="confirmationFormItem">
                <label class="confirmationLabel"
                  htmlFor="phoneNumber">Număr de telefon</label>
                <input
                  class="confirmationInput"
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={guestData.phoneNumber}
                  onChange={handleInputChange}
                  placeholder="+40 123 456 789"
                />
                {showPhoneError && <div class="errorLabel"><p>Te rugăm să ne spui numarul de telefon.</p></div>}
              </div>
              <div class="confirmationFormItem">
                <label class="confirmationLabel"
                  htmlFor="menuType">Tip Meniu</label>
                <select
                  class="confirmationInput"
                  id="menuType"
                  name="menuType"
                  value={guestData.menuType}
                  onChange={handleInputChange}>
                  <option class="confirmationInput" value="choose-menu">Alegeti tipul de meniu</option>
                  <option class="confirmationInput" value="normal">Normal</option>
                  <option class="confirmationInput" value="vegetarian">Vegetarian</option>
                  <option class="confirmationInput" value="vegan">Vegan</option>
                  <option class="confirmationInput" value="intoleranta">Cu Intoleranţă</option>
                </select>
                {showMenuError && <div class="errorLabel"><p>Te rugăm să ne spui ce tip de meniu dorești.</p></div>}
                {guestData.menuType === "intoleranta" && (
                  <div class="intoleranceHolder">
                    <label
                      class="confirmationLabel"
                      htmlFor="intoleranceDetails">
                      Detalii intoleranță:
                    </label>
                    <input
                      class="confirmationInput"
                      type="text"
                      name="intolerance"
                      id="intolerance"
                      placeholder="Specificati intoleranta"
                      value={guestData.intolerance}
                      onChange={handleInputChange}
                    />
                    {showIntoleranceError && <div class="errorLabel"><p>Te rugăm să ne spui ce fel de intoleranta alimentara ai.</p></div>}
                  </div>
                )}

              </div>
              <button class="confirmationButton" type="submit" onClick={handleAddGuest}>Confirma</button>
            </form>
            <p class="phoneContactTitle">Sau puteti sa ne confirmi telefonic la urmatoarele numere:</p>
            <div class="phoneContactHolder">
              <a class="phoneContactPhone" href="tel:+40728397900">+40728397900 - Mara</a>
              <a class="phoneContactPhone" href="tel:+40742884593">+40742884593 - Mircea</a>
            </div>

            <h3 class="formSubtitle">Te așteptăm cu drag!</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
