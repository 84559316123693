import React from 'react';
import './HeaderCard.css'

function HeaderCard({ title1, title2, titleImg, description }) {
    return (
        <div class="headerCard">
            <div class="headerCardTitle">
                <h1 class="headerTitle">{title1}</h1>
                <img class="headerTitleImage" src={titleImg}/>
                <h1 class="headerTitle">{title2}</h1>
            </div>

            {description.map((descriptionText, index) => (
                <h3 class="HeaderText" key={index}>{descriptionText}</h3>
            ))}
        </div>
    );
}

export default HeaderCard;