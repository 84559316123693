import React from 'react';
import './TextWithBackgroundAndBottomDate.css'

function TextWithBackgroundAndBottomDate({ date, text, text2, icon, backgroundImagePath }) {
    return (
        <div class="TextWithBackgroundAndBottomDateHolder">
            <p className='TextWithBackgroundAndBottomDateText'>{text}</p>
            <p className='TextWithBackgroundAndBottomDateText'>{text2}</p>
            <p className='TextWithBackgroundAndBottomDateDate'>{date}</p>
        </div>
    )
}

export default TextWithBackgroundAndBottomDate;