import React from 'react';
import './HighlightedTitleWithSubtitle.css';

function HighlightedTitleWithSubtitle({ title, subtitle, separatorText }) {
  // Filter out dead subtitles and group the remaining ones into pairs
  const subtitlePairs = subtitle.reduce((acc, item, index) => {
    if (index % 2 === 0) {
      acc.push([item]);
    } else {
      acc[acc.length - 1].push(item);
    }
    return acc;
  }, []);

  return (
    <div className="highlightedTitleAndSubtitleHolder">
      <h1 className="highlightedTitleAndSubtitleH1">{title}</h1>
      {subtitlePairs.map((pair, index) => (
        <div key={index} className="subtitlePair">
          {pair.map((subtitleText, subIndex) => (
            <div className="subtitlePair">
            {(subtitleText.isDead)? <h3 className='isDeadWingLeft'>🪽</h3> : <h3></h3>}
                
            <h3 key={subIndex} className={`highlightedTitleAndSubtitleH3 ${subtitleText.isDead ? 'isDead' : 'liveSubtitle'}`}>
              {subtitleText.text}
            </h3>
            {(subtitleText.isDead)? <h3 className='isDeadWing'>🪽</h3> : <h3></h3>}
            {subIndex !== pair.length - 1 && <h3 className="h3ExtraWord">{" și "}</h3>}
            </div>
          ))}
          
        </div>
      ))}
    </div>
  );
}

export default HighlightedTitleWithSubtitle;

