import React, { useState, useEffect } from 'react';
import './CountdownToDate.css'
import CountdownElement from './CountdownElement';

function CountdownToDate({title, targetDate, backgroundImagePath}){

    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      useEffect(() => {
        const calculateTimeLeft = () => {
          // Set the target date (replace with your desired date)
          const countDownDate = new Date(targetDate).getTime();
    
          // Get today's date and time
          const now = new Date().getTime();
    
          // Find the difference between target date and current date
          const distance = countDownDate - now;
    
          // Time calculations for days, hours, minutes, and seconds
          const oneDay = 24 * 60 * 60 * 1000;
          const oneHour = 60 * 60 * 1000;
          const oneMinute = 60 * 1000;
          const oneSecond = 1000;
    
          const days = Math.floor(distance / oneDay);
          const hours = Math.floor((distance % oneDay) / oneHour);
          const minutes = Math.floor((distance % oneHour) / oneMinute);
          const seconds = Math.floor((distance % oneMinute) / oneSecond);
    
          setCountdown({ days, hours, minutes, seconds });
    
          // If the countdown is finished, stop the timer
          if (distance < 0) {
            clearInterval(timerId);
          }
        };
    
        const timerId = setInterval(calculateTimeLeft, 1000);
    
        return () => clearInterval(timerId);
      }, [targetDate]); // Dependency array ensures recalculation on targetDate change
    
      const { days, hours, minutes, seconds } = countdown;
    
    return( 
        <div className="countDownToDateHolder"
        style={{ backgroundImage: `url(${backgroundImagePath})` }}>
            <p class="countDownToDateTitle">{title}</p>
            <div className='countdownElements'>
              <CountdownElement label={"zile"} value={days}/>
              <CountdownElement label={"ore"} value={hours}/>
              <CountdownElement label={"minute"} value={minutes}/>
              <CountdownElement label={"secunde"} value={seconds}/>
            </div>
        </div>
    )
}

export default CountdownToDate;